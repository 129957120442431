import { useUpdateDashboardMutation } from '@/main/queries/dashboard/dashboard';
import { Box, Text } from '@/shared/design-system/v2';
import { GridLayout } from '../common/grid-layout/GridLayout';
import { DashboardModel } from './util';

interface DashboardProps {
  dashboard: DashboardModel;
}

export const Dashboard = ({ dashboard }: DashboardProps) => {
  const { mutateAsync: updateDashboard } = useUpdateDashboardMutation();

  const handleLayoutChange = (l: any) => {
    updateDashboard({
      ...dashboard,
      layout: l,
    });
  };

  const layout = dashboard.layout;

  return (
    <Box>
      <Text>Dashboard {dashboard.dashboardId}</Text>
      <GridLayout className="grid" layout={layout} onLayoutChange={handleLayoutChange}>
        {layout.map((l: any) => (
          <GridLayout.Item key={l.i} data-layout-id={l.i}>
            <Box>Visualizations {l.i}</Box>
          </GridLayout.Item>
        ))}
      </GridLayout>
    </Box>
  );
};
