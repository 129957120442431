import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Navigate, Route, RouteProps, Routes, useLocation } from 'react-router-dom';
import { closeModal } from '@/shared/design-system/v2';
import { useDataAnalyticsHowItWorksModal } from '../components/data-analytics/v2/DataAnalyticsHowItWorksModal';
import { useFreeTrialModal } from '../components/subscriptions/FreeTrialModal';
import { useUpgradePlanModal } from '../components/subscriptions/plans/UpgradePlanModal';
import { UPGRADE_PLAN_MODAL_ID } from '../components/subscriptions/plans/util';
import { PaymentSubscriptionStatusType } from '../generated/api';
import { useQueryParams } from '../hooks/useQueryParams';
import { AccessDeniedPage } from '../pages/AccessDenied';
import { UnavailablePage } from '../pages/Unavailable';
import { GlobalHomePage } from '../pages/home/GlobalHome';
import { WorkspaceContainerPage } from '../pages/home/WorkspaceContainer';
import {
  useGetWorkspaceCurrentSubscriptionDetailsQuery,
  useGetWorkspaceTrialEligibilityQuery,
} from '../queries/account/payment-and-subscription';
import { AppBuilderRouter } from './AppBuilderRouter';
import { AppStoreRouter } from './AppStoreRouter';
import { AuthRoute, renderAuthRoute } from './AuthRouter';
import { DataAnalyticsRouter } from './DataAnalyticsRouter';
import { DataLabelingRouter } from './DataLabelingRouter';
import { DataSourcesRouter } from './DataSourcesRouter';
import { DatasetsRouter } from './DatasetsRouter';
import { ExperimentsRouter } from './ExperimentsRouter';
import { ModelAppsRouter } from './ModelAppsRouter';
import { ModelsRouter } from './ModelsRouter';
import { ProjectsRouter } from './ProjectsRouter';
import { RecordingsRouter } from './RecordingsRouter';
import { SnippetsRouter } from './SnippetsRouter';
import { AppRoutes, getDescendantRoute, getRoute } from './constants';
import { DashboardsRouter } from './dashboards/DashboardRouter';
import { lazyWithRetry } from './util';
import { WorkflowsRouter } from './workflows/router';

const Settings = lazyWithRetry(() => import('../pages/Settings'));
const SubscriptionPlans = lazyWithRetry(() => import('../pages/Subscriptions/SubscriptionPlans'));
const DocSearchPage = lazyWithRetry(() => import('../pages/DocSearch/DocSearch'));
const RunsPage = lazyWithRetry(() => import('../pages/Runs'));
const ModelStudioHomePage = lazyWithRetry(() => import('../pages/home/ModelStudioHome'));
const DataStudioHomePage = lazyWithRetry(() => import('../pages/home/DataStudioHome'));

const useWorkspaceRoutes = (): RouteProps[] => {
  const {
    featureTextToSql,
    featureSnippets,
    featureModelStudio,
    featureDataStudio,
    featureDashboard,
  } = useFlags();

  // Initialize the base routes
  let routes: RouteProps[] = [
    {
      path: getDescendantRoute(AppRoutes.DATA_SOURCES),
      element: <DataSourcesRouter />,
    },
    {
      path: getDescendantRoute(AppRoutes.MODELS),
      element: <ModelsRouter />,
    },
    {
      path: getDescendantRoute(AppRoutes.WORKFLOWS_HOME),
      element: <WorkflowsRouter />,
    },
    {
      path: getRoute(AppRoutes.RUNS),
      element: <RunsPage />,
    },
    {
      path: getRoute(AppRoutes.SETTINGS),
      element: <Settings />,
    },
    {
      path: getRoute(AppRoutes.SUBSCRIPTION_PLANS),
      element: <SubscriptionPlans />,
    },
    {
      path: getDescendantRoute(AppRoutes.APP_STORE),
      element: <AppStoreRouter />,
    },
    {
      path: getDescendantRoute(AppRoutes.AI_APPS),
      element: <AppBuilderRouter />,
    },
    {
      path: getRoute(AppRoutes.DOCUMENT_SEARCH),
      element: <DocSearchPage />,
    },
    {
      path: getRoute(AppRoutes.ERROR_ROUTE),
      element: <UnavailablePage />,
    },
    {
      path: getRoute(AppRoutes.ACCESS_DENIED),
      element: <AccessDeniedPage />,
    },
    {
      path: '*',
      element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
    },
  ];

  // Conditionally add routes based on feature flags
  if (featureDashboard) {
    routes = routes.concat([
      {
        path: getDescendantRoute(AppRoutes.DASHBOARDS),
        element: <DashboardsRouter />,
      },
    ]);
  }

  // Conditionally add routes based on feature flags
  if (featureModelStudio) {
    routes = routes.concat([
      {
        path: getDescendantRoute(AppRoutes.EVALUATIONS),
        element: <RecordingsRouter />,
      },
      {
        path: getDescendantRoute(AppRoutes.EXPERIMENTS),
        element: <ExperimentsRouter />,
      },
      {
        path: getDescendantRoute(AppRoutes.PROJECTS),
        element: <ProjectsRouter />,
      },
      {
        path: getRoute(AppRoutes.MODEL_STUDIO_HOME),
        element: <ModelStudioHomePage />,
      },
    ]);
  }

  if (featureDataStudio) {
    routes = routes.concat([
      {
        path: getDescendantRoute(AppRoutes.DATASETS),
        element: <DatasetsRouter />,
      },
      {
        path: getDescendantRoute(AppRoutes.DATA_LABELING),
        element: <DataLabelingRouter />,
      },
      {
        path: getDescendantRoute(AppRoutes.MODEL_APPS),
        element: <ModelAppsRouter />,
      },
      {
        path: getRoute(AppRoutes.DATA_STUDIO_HOME),
        element: <DataStudioHomePage />,
      },
    ]);
  }

  if (featureSnippets) {
    routes = routes.concat([
      {
        path: getDescendantRoute(AppRoutes.SNIPPETS),
        element: <SnippetsRouter />,
      },
    ]);
  }

  if (featureTextToSql) {
    routes = routes.concat([
      {
        path: getDescendantRoute(AppRoutes.DATA_ANALYTICS),
        element: <DataAnalyticsRouter />,
      },
    ]);
  }

  return routes;
};

export const WorkspaceRouter = (): JSX.Element => {
  const { featureGlobalHomepage } = useFlags();
  const query = useQueryParams();
  const location = useLocation();
  const workspaceRoutes = useWorkspaceRoutes();
  const { open: openFreeTrialModal } = useFreeTrialModal();
  const { open: openUpgradePlanModal } = useUpgradePlanModal();
  const { open: openDataAnalyticsHowItWorksModal } = useDataAnalyticsHowItWorksModal();

  const { isEligible: isEligibleForTrial } = useGetWorkspaceTrialEligibilityQuery();
  const { data: subscriptionDetails } = useGetWorkspaceCurrentSubscriptionDetailsQuery();

  // Show modal post-onboarding to notify non-enterprise users about free trial
  const onboardComplete = query.get('onboardComplete');
  const isDataAnalyticsRoute = location.pathname.includes(getRoute(AppRoutes.DATA_ANALYTICS));
  useEffect(() => {
    if (onboardComplete && isEligibleForTrial) {
      if (isDataAnalyticsRoute) {
        openDataAnalyticsHowItWorksModal();
      } else {
        openFreeTrialModal();
      }
    }
  }, [onboardComplete, isEligibleForTrial, isDataAnalyticsRoute]);

  // If workspace's free trial has expired, show full-screen modal with pricing plans
  const shouldPromptUpgrade =
    !!subscriptionDetails &&
    (subscriptionDetails.status === PaymentSubscriptionStatusType.Canceled ||
      subscriptionDetails.status === PaymentSubscriptionStatusType.Incomplete ||
      subscriptionDetails.status === PaymentSubscriptionStatusType.Paused);
  useEffect(() => {
    if (shouldPromptUpgrade) {
      openUpgradePlanModal();
    } else {
      // Close the upgrade modal once subscription plan is upgraded. Noop if modal is closed.
      closeModal(UPGRADE_PLAN_MODAL_ID);
    }
  }, [shouldPromptUpgrade]);

  const rootEl = featureGlobalHomepage ? (
    <GlobalHomePage />
  ) : (
    <Navigate to={getRoute(AppRoutes.WORKFLOWS_HOME)} />
  );

  return (
    <Routes>
      <Route path="/" element={<AuthRoute element={<WorkspaceContainerPage />} />}>
        <Route index element={rootEl} />
        {workspaceRoutes.map(renderAuthRoute)}
      </Route>
    </Routes>
  );
};
